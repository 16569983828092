@tailwind base;

body {
  font-size: 14px;
}

.ant-spin-dot-item {
  background-color: #000;
}

.ant-switch-checked {
  background-color: #999;
}

.ant-collapse-header-text {
  color: white;
  font-size: 20px;
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.w-500 {
  width: 500px;
}

.brand-img {
  width: 224px;
}

.block-shadow {
  box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
    0 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.light-shadow {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.dark-shadow {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06),
    0 1px 3px 0 rgba(16, 24, 40, 0.1);
}

.border-12 {
  border-width: 12px;
}

.w-688 {
  max-width: 688px;
}

.circle {
  width: 70px;
  height: 70px;
}

.circle img {
  max-width: 28px;
}

.input-shadow {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.sideBarStepWrap {
  width: 440px;
  position: relative;
  height: 100vh;
}

.registerBlock {
  width: 720px;
}

.stepListWrap li {
  position: relative;
}

.stepListWrap li:after {
  background-color: #eaecf0;
  width: 2px;
  content: "";
  position: absolute;
  height: 100%;
  top: 32px;
  margin-left: 16px;
  z-index: -1;
}

.stepListWrap li.step-cmpt:after {
  background-color: #5ab8ed;
}

.stepListWrap li:last-child::after {
  display: none;
}

.input-shadow-none {
  box-shadow: none !important;
}

.whiteBtnShadow {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(52, 64, 84, 0.6);
}

.modal {
  position: fixed;
  z-index: 100;
  overflow: hidden;
  max-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 336px;
}

select {
  padding-right: 30px !important;
}

.border-6 {
  border-width: 6px;
}

input.input-shadow:focus,
select:focus,
textarea:focus {
  box-shadow: 0 0 0 4px #d1e9ff, 0 1px 2px 0 rgb(16 24 40 / 5%);
  border: 1px solid #84bff3;
}

textarea {
  resize: none;
}

::placeholder {
  color: #667085;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #667085;
}

::-ms-input-placeholder {
  color: #667085;
}

.primary-btn:focus {
  box-shadow: 0 0 0 4px #d1e9ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.secondary-btn:focus {
  box-shadow: 0 0 0 4px var(--gray-100), 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

@tailwind components;
@tailwind utilities;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="radio"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply px-2 py-2 rounded-sm focus:ring-gray-400 focus:border-gray-400;
  }
}

@layer components {
  .test {
    /* @apply border border-primary; */
  }

  .vertical-scroll {
    @apply scrollbar scrollbar-thin hover:scrollbar-thumb-gray-500 scrollbar-thumb-gray-200;
  }

  .btn-border {
    @apply px-2 py-1 text-white border border-gray-100 rounded-lg cursor-pointer;
  }
}

@media screen and (max-width: 1100px) {
  .registerBlock {
    width: 590px;
  }

  .sideBarStepWrap {
    width: 400px;
    min-height: 100vh;
    height: auto;
  }

  .registerBlock {
    width: 590px;
  }
}

@media screen and (max-width: 991px) {
  .sideBarStepWrap {
    width: 370px;
  }

  .registerBlock {
    width: 500px;
  }

  .form-footer .pre-step,
  .form-footer .next-step {
    font-size: 0;
    width: 44px;
  }

  .form-footer .pre-step img,
  .form-footer .next-step img {
    margin: 0 !important;
  }

  .step2-btn-block .flex.justify-end.flex-wrap {
    justify-content: space-between !important;
  }
}

@media screen and (max-width: 900px) {
  .sideBarStepWrap {
    width: 100%;
    position: relative;
    min-height: auto;
    height: auto;
  }

  form,
  .registerBlock {
    width: 100%;
  }

  .back-btn-hide {
    display: none;
  }

  form.pt-20 {
    padding-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .w-500 {
    width: 100%;
  }

  .stepListWrap {
    margin-bottom: 0;
  }

  .stepListWrap li {
    display: none;
  }

  .stepListWrap li.active {
    display: block;
    margin-bottom: 0;
  }

  .stepListWrap li:after {
    background-color: #5786cc;
    width: 24px;
    content: "";
    position: absolute;
    height: 4px;
    top: 50%;
    margin-left: 0;
    right: 0;
    z-index: 1;
    margin-right: -24px;
    border-radius: 2px 0 0 2px;
    margin-top: -2px;
  }

  .sideBarStepWrap {
    padding: 24px 0 27px 0;
  }

  .contactBlockWrap > .flex,
  .setMobileSelect {
    flex-wrap: wrap;
  }

  .contactBlockWrap .w-1\/5 {
    width: 94px;
  }

  .contactBlockWrap .w-2\/5,
  .contactBlockWrap .flex-1,
  .setMobileSelect .flex-1 {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    flex: 100%;
  }

  .form-footer {
    justify-content: space-between;
  }

  .form-footer .px-6 {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .upload-img {
    width: 100%;
  }

  .form-footer .ml-auto {
    margin-left: 0;
  }

  .step-two:before,
  .step-3:before {
    content: "";
    position: absolute;
    width: 21px;
    height: 4px;
    background: #5786cc;
    left: 0;
    top: 14px;
    z-index: 0;
    margin-left: -24px;
  }

  .w-44 {
    width: 167px;
  }

  .w-500 {
    width: 342px;
  }

  .w-500 p br {
    display: none;
  }

  .active br {
    display: none;
  }

  .step-two a > div {
    width: 75%;
  }
}

@media screen and (min-width: 767px) {
  .show-on-mobile {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .modal {
    min-width: 310px;
  }

  .w-44 {
    width: 130px;
  }

  .w-500 {
    width: 100%;
  }
}

.side-nav {
  width: 67px;
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  z-index: 7;
  top: 0;
  left: 0;
}

.menu-list {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-action-item {
  display: none;
}

.hoveredMenu .header-action-item,
.side-nav-header:hover .header-action-item {
  display: flex;
}

.hoveredMenu .logo,
.side-nav-header:hover .logo {
  display: none;
}

.header-action-item:hover svg path {
  stroke: #0e4e9a;
}

.header-action-item:focus {
  background: transparent;
  box-shadow: 0 0 0 4px #d1e9ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.showPanel .sidePanel {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 100vh;
}

.sidePanel {
  width: 176px;
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  z-index: 2;
  left: -176px;
}

.side-nav-wrap {
  position: relative;
}

.hoveredMenu .sidePanel {
  left: 67px;
  z-index: 22;
}

.menu-item a {
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  white-space: nowrap;
  width: 100%;
  color: #344054;
  font-size: 16px;
  height: 100%;
}

.menu-collapse-item {
  align-items: center;
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  height: 2.5rem;
  justify-content: space-between;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  -webkit-user-select: none;
  user-select: none;
  color: #344054;
  font-size: 16px;
}

.menu-item a:hover,
.menu-item a.active {
  background: #d2ebff;
  border-radius: 6px;
}

.menu-collapse ul {
  padding-left: 0;
}

nav.menu {
  margin-top: 20px;
}

.menu-collapse-item svg {
  fill: #667085;
}

.showPanel .logo {
  display: none;
}

.showPanel .side-nav-header button {
  display: flex;
}

.showPanel .side-nav-header button svg {
  transform: rotate(180deg);
}

.avatar-img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.avatar-circle {
  border-radius: 9999px;
}

.header {
  background-color: #eff8ff;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
  padding: 7px 0;
}

.dropdown-menu {
  background-color: #fff;
  min-width: 240px;
  position: absolute;
  z-index: 30;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
}

.menu-item-divider {
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-left: -8px;
  margin-right: -8px;
}

.dropdown-toggle {
  cursor: pointer;
  padding: 0.5rem;
}

.header-action,
.header-wrapper {
  align-items: center;
  display: flex;
}

.header-wrapper {
  justify-content: space-between;
  padding: 0 1rem;
  position: relative;
  width: 100%;
}

.MuiMenuItem-root {
  padding-top: 10px !important;
  padding-bottom: 8px !important;
  font-family: Raleway, ui-sans-serif, system-ui, -apple-system !important;
  font-size: 12px !important;
  color: rgba(52, 64, 84, 1) !important;
}

.dropdown-toggle:visit span svg {
  transform: rotate(-180deg);
}

.header-action-item-hoverable {
  transition-duration: 0.3s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.header-action-item {
  border-radius: 9999px;
  cursor: pointer;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding: 0.5rem;
}

.header-action-item-hoverable:hover {
  --tw-bg-opacity: 0.05;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.dropdown-menu.bottom-end {
  right: 0;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.notification {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.dropdown .items-center:focus-within span svg {
  transform: rotate(-180deg);
}

.menu-group.active {
  background-color: rgb(210 235 255 / var(--tw-bg-opacity));
  color: #344054;
}

.input-suffix-start {
  --tw-translate-y: -50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.w-29 {
  width: 120px;
}

.gray-line-set:before {
  content: "";
  position: absolute;
  top: -25px;
  bottom: -12px;
  width: 4px;
  background-color: #eaecf0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.hide {
  display: none;
}

.roate-90 img {
  transform: rotate(90deg);
}

.dialog-overlay-after-open {
  opacity: 1;
}

.dialog-overlay {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(52, 64, 84, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.15s ease-in-out;
  z-index: 30;
}

.dialog {
  max-width: 72rem;
}

.dialog {
  margin-left: auto;
  margin-right: auto;
  outline: none;
}

.dialog-content {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.dialog-content {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 100%;
  position: relative;
}

.close-btn {
  right: 1.2rem !important;
}

.close-btn svg {
  fill: #667085;
}

.close-btn-default {
  border-radius: 0.25rem;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.25rem;
}

.close-btn {
  outline: 2px solid transparent;
  outline-offset: 2px;
  margin-top: 14px;
  margin-right: -10px;
}

.steps {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.bg-indigo-600,
.bg-indigo-700 {
  --tw-bg-opacity: 1;
}

.bg-indigo-600 {
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.step-item-pending {
  border-top: 4px solid #eaecf0;
}

.step-item-in-progress {
  border-top: 4px solid #5ab8ed;
}

.step-item-complete {
  border-top: 4px solid #0e4e9a;
}

.steps .step-item {
  flex: 1 !important;
  padding-top: 14px;
}

.step-item-wrapper {
  display: flex;
}

.step-item-wrapper * {
  font-weight: 600;
  color: #344054;
  font-size: 14px;
}

.step-item-icon {
  margin-right: 5px;
}

.step-item-in-progress .step-item-wrapper * {
  color: #0e4e9a;
}

.add-client-form .columns-input {
  width: 604px;
}

.add-client-form .lavel-wd {
  width: 200px;
}

.segment {
  display: flex;
}

.segment button {
  border: 1px solid #d0d5dd;

  font-size: 12px;
  font-weight: 600;
}

.segment button span {
  display: block;
}

.segment button:first-child {
  border-radius: 8px 0 0 8px;
  border-right: none !important;
}

.segment button:nth-child(3) {
  border-left: none !important;
}

.segment button:last-child {
  border-radius: 0 8px 8px 0;
}

.dailog-footer {
  padding: 24px;
  border-top: 1px solid #eaecf0;
  margin-top: 32px;
}
.dailog-view-profile {
  padding: 15px;
  border-top: 1px solid #eaecf0;
  margin-top: 0px;
}
.segment-item-active {
  background: #d2ebff;
}

.badge-dot {
  height: 8px;
  width: 8px;
}

.badge-dot {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  border-radius: 9999px;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 0.75rem;
  line-height: 1rem;
  display: inline-block;
}

.tab-list {
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.border-gray-100 .tab-list {
  gap: 8px;
}

.tab-nav-pill {
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 600;
  color: #667085;
  text-align: center;
  width: 100%;
  white-space: nowrap;
}

.hover-svg:hover path {
  stroke: #344054;
}

.tab-nav-active {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06),
    0 1px 3px 0 rgba(16, 24, 40, 0.1);
}

.notification-list ul:last-child {
  border-bottom: none;
}

.scrollCstm > div:nth-child(3) {
  width: 8px !important;
  border-radius: 6px !important;
}

.scrollCstm > div:nth-child(3) div {
  width: 8px !important;
  border-radius: 6px !important;
  background: #d0d5dd !important;
}

.rounded-xl {
  border-radius: 12px !important;
}

.addContact-dialog {
  max-width: 720px !important;
}

.customAlert {
  max-width: 400px !important;
}

.dialog-overlay-after-open {
  display: grid;
  align-items: center;
  overflow-y: auto;
  padding: 20px 0;
}

.dialog-content {
  margin: 0 !important;
}

.nmx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.nmb-6 {
  margin-bottom: -1.5rem;
}

.notes-table td {
  vertical-align: top;
}

.min-h06 {
  min-height: 106px;
}

.templates-list li button {
  color: #475467;
  font-weight: 600;
  font-size: 12px;
  padding: 8px 25px;
  text-align: left;
}

.h-595 {
  height: 595px;
}

.h-500 {
  height: 500px;
}

.ql-container.ql-snow {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px 8px 0 0;
  min-height: 200px;
  border-bottom: none !important;
}

.ql-toolbar.ql-snow {
  border-radius: 0 0 8px 8px;
  border: 1px solid #d0d5dd !important;
  border-top: none !important;
}

.ql-formats .ql-header.ql-picker {
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
  border: 1px solid #f2f4f7 !important;
  color: #344054;
  font-size: 12px;
  height: 36px;
  line-height: 36px;
}

.ql-formats button {
  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
  border: 1px solid #f2f4f7 !important;
  width: 36px !important;
  height: 36px !important;
  padding: 8px !important;
  border-radius: 8px !important;
  margin-right: 14px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  font-family: "Raleway" !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: transparent !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border: 1px solid #f2f4f7 !important;
  border-radius: 8px !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  line-height: normal !important;
  height: auto !important;
}

button > a {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.userList .tabs {
  display: flex;
  align-items: self-start;
}

.userList .tabs .tab-list.tab-list-pill {
  flex-direction: column;
  padding: 4px;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  width: 115px;
}

.userList .tab-nav-pill {
  min-width: 107px;
}

.userList .tab-content.tab-content-active {
  width: 93.6%;
}

@media only screen and (max-width: 1280px) {
  .dialog {
    max-width: 95%;
  }
}

.switcher {
  align-items: center;
  background-color: #f2f4f7;
  border-radius: 1.5rem;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  width: 36px;
  position: relative;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.switcher input {
  display: none;
}

.switcher.switcher-checked .switcher-toggle,
.switcher.switcher-checked .switcher-toggle-loading {
  left: calc(100% - 1.15rem);
}

.switcher-toggle {
  height: 16px;
  left: 0.125rem;
  position: absolute;
  top: 0.125rem;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06),
    0 1px 3px 0 rgba(16, 24, 40, 0.1);
  width: 16px;
  border-radius: 50%;
}

.switcher-toggle:before {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.switcher.switcher-checked .switcher-content {
  margin: 0 1.65rem 0 0.45rem;
}

.switcher-content {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  margin: 0 0.45rem 0 1.65rem;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.dark .switcher,
.switcher {
  --tw-bg-opacity: 1;
}

.switcher-checked.bg-blue-700 {
  background-color: #5786cc;
}

.reset-accordian-btn {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 16px;
  border: 1px solid #eaecf0;
  border-radius: 12px !important;
  text-align: left;
  margin-top: 8px;
}

.reset-accordian-btn span.w-5.h-5 {
  width: 30px;
  height: 30px;
}

.text-blue-gray-900.reset-accordian-btn {
  border-bottom: none !important;
  border-radius: 12px 12px 0 0 !important;
}

.reset-accordian-btn svg path {
  color: #5786cc;
}

.reset-accordian-btn svg {
  width: 30px;
}

.dailog-footer.mt-0 {
  margin-top: 0;
}

.accordion-body {
  border: 1px solid #eaecf0 !important;
  border-radius: 0 0 12px 12px;
}

.min-h-500 {
  min-height: 660px;
}

.userList .tab-nav-pill {
  text-align: left;
}

.details-modal .col-1 {
  width: 225px;
}

.details-modal .col-2 {
  width: 73.5%;
}

.specificSiteTab .tab-nav {
  min-width: 212px;
}

.upload-draggable {
  --tw-border-opacity: 1;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: 1px solid #eaecf0;
  padding: 24px 16px 16px;
}

.upload-input.draggable {
  cursor: pointer;
  display: block;
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.upload-file-list .upload-file {
  margin-top: 8px;
}

.upload-file {
  align-items: center;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d0d5dd;
  padding: 16px;
}

.dark .upload-file,
.upload-file {
  --tw-border-opacity: 1;
}

.upload-file-remove {
  margin-top: 0;
}

.upload {
  display: inline-block;
  position: relative;
}

.tooltip {
  --tw-text-opacity: 1;
  border-radius: 0.5rem;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 50;
  background-color: #1d2939;
  max-width: 320px;
}

.tooltip-wrapper {
  display: inline-flex;
}

.upload-file-remove svg {
  opacity: 0;
}

.upload-file-remove {
  background-repeat: no-repeat;
  background-size: 20px 21px;
  opacity: 0.7;
  background-position: center;
}

.hideIcons .outline-8 {
  display: none;
}

.hideIcons .pt-4 {
  padding-top: 0;
}

.hideIcons .upload-file {
  width: 49.1%;
  margin-top: 0;
}

.hideIcons .upload-file-list {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 12px;
}

.mh-430 {
  min-height: 328px;
}

.h-400 {
  height: 400px;
}

.upload {
  display: inline-block;
  position: relative;
}

.upload-input {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.avatar-icon {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.sign-up-tab {
  padding: 0 !important;
  width: 48%;
  box-shadow: none;
  border: 2px solid transparent;
}

.sign-up-tab > div {
  box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
    0 4px 8px -2px rgba(16, 24, 40, 0.1);
  height: 140px;
}

.sign-up-tab > div p {
  font-size: 14px;
}

.sign-up-tab:hover,
.sign-up-tab.tab-nav-active {
  background-color: #f5faff;
}

.sign-up-tab:focus {
  background-color: #f5faff;
  border: 2px solid #5fb8ea;
}

.input-wrapper {
  display: flex;
  position: relative;
}

.radio-group.vertical {
  flex-direction: column;
}

.radio-group {
  display: inline-flex;
}

.radio-group .radio-label {
  align-items: center;
}

.radio {
  --tw-border-opacity: 1;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  -webkit-appearance: none;
  appearance: none;
  border-color: #d0d5dd;
  border-radius: 9999px;
  border-width: 1px;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 16px;
  padding: 0;
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  width: 16px;
}

.radio:focus {
  outline: none;
  box-shadow: none;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  color: #5786cc;
  background-color: #eff8ff !important;
  border: 1px solid #5786cc !important;
}

.payment-btn button span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.input-suffix-end {
  --tw-translate-y: -50%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -12px;
  border: 1px solid #f2f4f7;
  border-radius: 4px;
  height: 24px;
  width: 36px;
  justify-content: center;
  align-items: center;
}

.h-75 {
  height: 300px;
}

.w-440 {
  width: 440px;
}

.w-480 {
  width: 480px;
}

.flex.upload {
  display: flex;
}

.docType-btn .segment-item-default {
  padding: 8px 16px;
}

@media only screen and (max-width: 1365px) {
  .specificSiteTab .tab-nav {
    min-width: 135px;
  }
}

.select-options .select__control {
  font-size: 0.875rem;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 100%;
}

.select__input {
  margin-left: 20px !important;
}

.select__placeholder,
.select__single-value {
  padding-left: 24px;
}

.docType-btn-md .segment-item-default {
  padding: 10px 16px;
}

.lg-dialog {
  width: 100% !important;
  max-width: 1224px;
}

.select-option .select__placeholder,
.select-option .select__single-value {
  padding-left: 0;
}

.select-option .select__control {
  height: 100%;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border-color: #d0d5dd;
}

.select__control--is-focused {
  box-shadow: 0 0 0 4px #d1e9ff, 0 1px 2px 0 rgb(16 24 40 / 5%) !important;
  border: 1px solid #84bff3 !important;
}

.select__menu .select-option {
  display: flex;
  justify-content: space-between;
  padding: 7px 10px 7px 5px;
  color: #475467;
  border-radius: 8px;
}

.select-option .select__control--is-focused .select__placeholder {
  font-weight: 500;
  color: #101828;
}

.select__menu .select-option:hover {
  background-color: #eff8ff;
}

.select__menu {
  padding: 2px 5px;
}

.select__menu .select-option svg {
  fill: #5ab8ed;
}

.left-side-block .tab-nav-pill {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  font-size: 14px;
}

.checkbox:focus {
  box-shadow: none;
}

.success-checkbox input[type="checkbox"]:checked + span {
  color: #039855;
}

.success-checkbox input[type="checkbox"]:checked {
  background-color: #ecfdf3;
  border-color: #039855 !important;
}

.error-checkbox input[type="checkbox"]:checked + span {
  color: #b42318;
}

.error-checkbox input[type="checkbox"]:checked {
  background-color: #fef3f2;
  border-color: #b42318 !important;
}

.video-player {
  width: 100% !important;
  height: 314px !important;
  border-radius: 8px;
  overflow: hidden;
}

.iFrame-Header {
  height: 60px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  background-color: rgba(16, 24, 40, 0.7);
}

.Image {
  flex-grow: 0;
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  cursor: pointer;
}
.btn-group button {
  height: 30px;
  width: 33px;
  font-size: 30px;
  line-height: 0;
  color: 666;
  border: 1px solid #d0d5dd;
}

.btn-group button:first-child {
  border-radius: 6px 0 0 6px;
}

.btn-group button:nth-child(2) {
  border-radius: 0 6px 6px 0;
  border-left: 1px solid #fff;
}

.inductionList .tab-nav {
  font-size: 14px;
  color: #344054;
}

.dropdown-btn {
  box-shadow: 0 0 0 4px #d1e9ff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px var(--blue-700);
  background-color: #5786cc;
  font-weight: 600;
  font-size: 12px;
}

.MuiMenuItem-root.justify-between {
  justify-content: space-between;
}

.dropdown-wrap .dropdown-menu {
  width: 295px;
  right: 0;
  padding: 6px;
}

.dropdown-wrap .dropdown-menu li {
  border-radius: 8px;
  padding: 8px;
}

.checkIcon {
  width: 21px;
}

.dropdown-wrap .dropdown-menu li .checkIcon svg {
  opacity: 0;
}

.dropdown-wrap .dropdown-menu li:hover {
  background-color: #eff8ff;
  padding: 8px;
}

.dropdown-wrap .dropdown-menu li:hover .checkIcon svg {
  opacity: 1;
}

.input-group {
  align-items: center;
  display: flex;
}

.input-addon {
  align-items: center;
  background-color: transparent;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 8px;
  border-width: 1px;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  border-right: none;
}

.input-group .input {
  z-index: 10;
  width: 100%;
}

.input-group > .input-addon:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group > .input:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: none;
}

.hoverSvg:hover svg path {
  stroke: #012963;
}

.hoverSvg:focus svg path {
  stroke: #5786cc;
}

.ql-editor.ql-blank::before {
  font-weight: 400;
  color: #667085 !important;
  font-style: normal !important;
  font-family: "Raleway";
}

.ql-editor p {
  font-size: 14px;
  font-family: "Raleway";
}

.upload_hover span:hover {
  color: #012963;
}

.upload_hover span:focus {
  color: #5786cc;
}

.upload_hover span:hover svg path {
  stroke: #012963;
}

.upload_hover span:focus svg path {
  color: #5786cc;
}

.searchWrapper {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  padding: 2px 30px 2px 8px !important;
  height: 40px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.searchWrapper input {
  padding: 0;
  margin-top: 0 !important;
  height: 34px;
  font-size: 14px;
  width: 100%;
  border: none !important;
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
}

.chip {
  background: #5786cc !important;
  border-radius: 18px !important;
  margin-bottom: 0 !important;
  padding: 0 15px !important;
  height: 28px !important;
  position: relative;
}

.chip img {
  opacity: 0;
  position: relative;
  z-index: 2;
}

.optionListContainer {
  border: 1px solid #eaecf0 !important;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px !important;
  padding: 5px !important;
  margin-top: 5px !important;
}

.optionContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}

.optionContainer::-webkit-scrollbar {
  width: 8px;
  background-color: #fff;
  margin-right: 4px;
}

.optionContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d0d5dd;
  margin-right: 4px;
}

.option {
  padding: 8px 10px 8px 8px !important;
  display: flex;
  align-items: center;
}

.highlightOption {
  background-color: transparent !important;
  color: #000 !important;
}

.option:hover,
.selected {
  background-color: #eff8ff !important;
  color: #000 !important;
  border-radius: 6px;
}

.option .checkbox {
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  background-color: #fff !important;
}

.multiSelectContainer ul {
  border: none !important;
}

.set-search-icon .search-wrapper {
  padding-left: 40px !important;
}

.h-9-5 {
  height: 38px;
}

.search-none .select__placeholder,
.search-none .select__single-value {
  padding-left: 0;
}

.search-none .select__input-container {
  background: none;
}

.css-1fdsijx-ValueContainer {
  padding: 2px 10px !important;
}

.search-none .select__input {
  margin-left: 0 !important;
}

.menu-list .segment {
  flex-direction: column;
  gap: 1px;
}

.menu-list .segment span {
  padding: 0;
}

.menu-list .segment button {
  border: none;
  padding: 6px 3px;
  border-radius: 6px;
}

.menu-list .segment button div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-list .segment button:hover,
.menu-list .segment-item-active {
  background-color: #d2ebff;
}

.tag {
  align-items: center;
  border-radius: 9999px;
  display: inline-flex;
  font-weight: 500;
  line-height: 1rem;
  padding: 0.25rem 0.625rem;
  white-space: nowrap;
  gap: 6px;
}

.tag-affix {
  --tw-bg-opacity: 1;
  border-radius: 9999px;
  height: 6px;
  width: 6px;
}

.text-12 {
  font-size: 0.75rem;
}

.tooltip {
  padding-top: 5px;
  padding-bottom: 5px;
}

.tooltip span {
  font-size: 12px;
}

.left-side-block .tab-nav-pill {
  color: #344054;
}

.border-l-0 {
  border-left: none !important;
}

.chooseEmail .select__indicators.css-1hb7zxy-IndicatorsContainer,
.chooseEmail .css-s72e72-Input:after {
  display: none;
}

.chooseEmail .select__placeholder,
.chooseEmail .select__single-value {
  padding-left: 0;
}

.chooseEmail .select__input-container {
  background: transparent;
}

.chooseEmail .select__input {
  margin-left: 0 !important;
}

.documentDialog {
  width: 100% !important;
  max-width: 100% !important;
  height: 100vh;
  border-radius: 0;
  margin: -20px 0;
}

.doc-view {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(52, 64, 84, 0.6);
}

.documentDialog .dialog-content {
  background: transparent;
}

.left-block-wrap {
  padding: 24px 24px 36px;
  border: solid 1px var(--gray-400);
  background-color: rgba(16, 24, 40, 0.7);
  height: 100%;
  border-right: 1px solid #98a2b3;
}

.doc-header {
  border: solid 1px var(--gray-400);
  background-color: rgba(16, 24, 40, 0.7);
  height: 80px;
}

.left-block-wrap textarea {
  border: 1px solid #d0d5dd;
  padding: 12px 14px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  font-size: 12px;
  height: 132px;
  resize: none;
}

.left-block-wrap .tab-nav-pill {
  color: #fff;
  width: 50%;
}

.left-block-wrap .tab-nav.tab-nav-pill.tab-nav-active {
  color: #344054;
}

.documentDialog .close-btn svg path {
  stroke: #fff;
}

.rightSidebtn {
  position: absolute;
  right: 60px;
  border-right: 1px solid #fff;
  padding: 5px 15px;
}

.bl-rds {
  border-radius: 12px 0 0 12px;
}

.br-rds {
  border-radius: 0 12px 12px 0;
}

/* 
  .selectType .select__placeholder ,  .selectType .select__placeholder{
    padding-left: 0;

  }
  */
.selectType .select__value-container {
  height: 38px;
}

.selectType .select__control.css-1ocetxi-control {
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.contractorTabsList .tab-nav-pill {
  min-width: 181px;
}
.contractorTabsList .worker_provider_tabs .tab-nav-pill {
  min-width: auto;
}
.h-430 {
  height: 328px;
}

.text-xs {
  font-size: 12px;
}

@media only screen and (max-width: 1360px) {
  .contractorTabsList .tab-nav-pill {
    min-width: auto;
  }
}

.client-portal-tab .tab-nav {
  width: 50%;
}

.reset-dropdown .dropdown .dropdown-toggle {
  padding: 0;
}

.reset-dropdown .dropdown button:focus {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.reset-dropdown .dropdown .dropdown-menu {
  right: 0;
  min-width: 184px;
  margin-top: 8px;
  padding: 5px;
}

.reset-dropdown .dropdown .dropdown-menu li {
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  color: #344054;
  display: flex;
  gap: 5px;
}

.hide-select-elt .select__input-container,
.hide-seacrh-icon .select__input-container {
  background-image: none;
}

.hide-select-elt .select__input-container .select__input,
.hide-seacrh-icon .select__input-container .select__input {
  margin-left: 0 !important;
}

.hide-select-elt .select__placeholder,
.hide-select-elt .select__single-value,
.hide-seacrh-icon .select__placeholder,
.hide-select-elt .select__single-value,
.hide-seacrh-icon .select__input,
.hide-seacrh-icon .select__single-value {
  padding-left: 0 !important;
}

.hide-select-elt .select__indicators.css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.message-box-height .ql-container.ql-snow {
  min-height: 132px;
}

.notification-table th {
  background-color: #fff;
}

.notification-table .divide-gray-200 {
  border-top: none !important;
}

.notification-table .divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  border: none !important;
}

.sideNav {
  width: 231px;
  padding: 28px 16px 28px 16px;
  background: #eff8ff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: sticky;
  top: 0;
}

.sideNav ul li a,
.logout_btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  font-weight: 700;
  border-radius: 6px;
  width: 100%;
  justify-content: start !important;
}

.logo-wrap {
  margin-bottom: 32px;
}

.sideNav ul li a.active,
.sideNav ul li a:hover {
  background: #d2ebff;
}

.sideNav ul li hr {
  border-top: 1px solid #fff;
  margin: 30px 0;
}

.validation-filter .segment button span {
  padding: 8px 16px;
}

.gray-300-shadow {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.articals-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* flex-wrap: wrap; */
  gap: 24px;
}

.articals-list li {
  width: 40%;
}

.resources-block {
  gap: 30px;
}

.resources-block > div {
  width: 49%;
}

.text-caption {
  padding-left: 30px;
}

.text-caption span {
  margin-bottom: 15px;
}

.message-box {
  border-radius: 0 12px 12px 12px;
}

.email-editer .ql-container.ql-snow {
  min-height: 155px;
}

.h-100 {
  height: 560px;
}

.h-100-px {
  height: 100px;
}

.upload-btn-header span.w-5.h-5.ml-4 {
  display: none;
}

.accordion-wrap {
  padding: 16px;
  border: 1px solid #eaecf0;
  border-radius: 12px !important;
  margin-top: 15px;
}

.accordion-wrap button,
.accordion-wrap .accordion-body {
  border: none !important;
  padding: 0;
  margin: 0;
}

.accordion-wrap .accordion-body {
  margin-top: 15px;
}

.form-align-wrap {
  height: 96%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.select-wrap-block .select__placeholder {
  padding-left: 0;
}

.select-wrap-block .select__input-container {
  background: none;
}

.select-wrap-block .select__input {
  margin-left: 0 !important;
}
.client-provider-tab .tab-list-pill {
  width: auto;
  justify-content: start;
}
.client-provider-tab .tab-nav-pill {
  border: 1px solid #d0d5dd;
  font-size: 12px;
  font-weight: 600;
  border-radius: 0;
  height: 38px;
}
.client-provider-tab .tab-nav-pill.tab-nav-active {
  background-color: #eff8ff;
  box-shadow: none;
}
.client-provider-tab .tab-nav-pill:first-child {
  border-radius: 8px 0 0 8px;
}
.client-provider-tab .tab-nav-pill:last-child {
  border-radius: 0 8px 8px 0;
  border-left: none;
}
.client-provider-tab .tab-nav-pill:nth-child(2),
.client-provider-tab .tab-nav-pill:nth-child(3) {
  border-left: none;
}
.flex .text-red-500.text-sm.mt-1 {
  width: 100%;
  font-size: 13px;
}

@media only screen and (max-width: 1280px) {
  .overflow-x-auto table {
    width: 1024px;
  }
}

@media only screen and (max-width: 991px) {
  .header-action.header-action-end .dropdown-menu {
    min-width: 400px;
  }

  .text-3xl {
    font-size: 1.575rem;
    line-height: 2.25rem;
  }

  .upload-img .font-inter {
    text-align: center;
  }

  .dialog-content .add-client-form .columns-input {
    width: 450px;
  }

  .grid-md-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .dialog-overlay .dialog {
    width: 100% !important;
  }
}

.pagination-wrap li a {
  display: inline-flex;
  border-radius: 50%;
  line-height: 36px;
}

.h-11.h-9 {
  height: 38px;
}

.segment button:nth-child(4),
.segment button:nth-child(5) {
  border-left: none !important;
}

.menu-list .h-11.h-9 {
  height: 65px;
}

.client_portal_csv {
  display: flex;
  gap: 5px;
  align-items: center;
}
.upload_csv {
  opacity: 0;
  position: absolute;
}

.video-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 921px;
  height: 518px;
}

.induction_iframe {
  width: 100%;
  height: 100%;
}

.video-container .close_iframeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
}

#video {
  position: relative;
  z-index: 99;
}

.compliance_search_icon {
  position: absolute;
  transform: translate(10px, 10px);
  z-index: 1;
}

button {
  cursor: pointer;
}

.image-container {
  overflow: hidden;
  transition: width 0.2s ease;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
}

.image-container img {
  display: block;
  width: 100%;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.doc-view .rpv-toolbar__left,
.doc-view
  .rpv-core__display--hidden.rpv-core__display--block-medium:first-child {
  display: none;
}

.doc-view .rpv-default-layout__toolbar,
.doc-view .rpv-default-layout__body,
.doc-view .rpv-core__inner-page {
  background-color: rgb(50 58 73);
}

.pdf_view_scroll {
  height: 100%;
}

.h-cal-full {
  height: calc(100vh - 100px);
}

.search_icon_contractor {
  position: relative;
}

.search_icon_contractor img {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.select__input-container {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBkPSJtMTcuNSAxNy42NTgtMi45MTctMi45MTdtMi4wODQtNWE3LjA4MyA3LjA4MyAwIDEgMS0xNC4xNjcgMCA3LjA4MyA3LjA4MyAwIDAgMSAxNC4xNjcgMHoiIHN0cm9rZT0iIzY2NzA4NSIgc3Ryb2tlV2lkdGg9IjEuNjY3IiBzdHJva2VMaW5lY2FwPSJyb3VuZCIgc3Ryb2tlTGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4NCg==");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 17px 17px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 14px !important;
}

.MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.MuiInputLabel-animated,
.css-14lo706,
.css-yjsfm1 {
  display: none !important;
}

.ml-n-12 {
  margin-left: -16px;
}

.progress.line {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

.progress-wrapper {
  width: 100%;
}

.progress-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: #eaecf0;
}

.progress-info.line {
  margin-left: 0.5rem;
}

.grid-container {
  grid-template-columns: 62% auto;
}

.picker {
  z-index: 40;
  border-radius: 0.5rem;
}

.picker-panel {
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.picker-view {
  display: flex;
  width: 100%;
  min-width: 260px;
  gap: 1.5rem;
}

.day-picker,
.month-picker,
.year-picker {
  width: 100%;
}

.picker-table {
  width: 100%;
  border-collapse: collapse;
}

.date-picker-week-cell {
  font-weight: 600;
}

.date-picker-cell {
  height: 2.25rem;
  width: 2.25rem;
  padding: 0;
  text-align: center;
}

.month-picker-cell,
.year-picker-cell {
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-weight: 600;
}

.year-table,
.month-table {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem;
}

.picker-header-label {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.date-picker-cell-hoverable:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.date-picker-cell-content {
  height: 100%;
  width: 100%;
  font-weight: 400;
}

.date-picker.input-suffix-end {
  border: none;
  width: auto;
  height: 23px;
}

.date-picker.input-suffix-end .close-btn {
  padding: 0;
  margin-top: 0;
}

.date-picker.input-suffix-end .close-btn svg {
  width: 20px;
  height: 20px;
}

.date-picker-cell-content.bg-indigo-600 {
  background: #5786cc;
  border: 1px solid #5786cc;
  border-radius: 50%;
}

.year-picker-cell.bg-indigo-600 {
  background: #5786cc;
}

.date-picker-cell-content.rounded-lg {
  border-radius: 50%;
}

.picker-header .flex.items-center button {
  height: 2.25rem;
  width: 2.25rem;
  position: relative;
}

.picker-header.flex.items-center button:hover {
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  border-radius: 50%;
}

.picker-header .flex.items-center button:after {
  position: absolute;
  left: 0;
  font-weight: 600;
  right: 0;
  margin: 0 auto;
  top: 4px;
  font-size: 18px;
}

.picker-header .flex.items-center button:first-child::after {
  content: "<";
}

.picker-header .flex.items-center button:nth-child(2)::after {
  content: ">";
}

.avatar-img {
  display: block;
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.MuiSelect-select.MuiSelect-outlined,
.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
  font-family: Raleway, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  padding: 0 15px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
  top: 0;
}

.MuiFormControl-root {
  height: 2.25rem !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -8px !important;
  font-family: Raleway, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px !important;
}

.MuiButtonBase-root.MuiCheckbox-root {
  padding: 0 5px 0 0;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  height: 100%;
}

.css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
  box-shadow: 0 0 0 4px #d1e9ff, 0 1px 2px 0 rgb(16 24 40 / 5%) !important;
  border: 1px solid #84bff3 !important;
}

.MuiInputBase-root.MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.rich_text_view .ql-toolbar span {
  display: none;
}

.css-13cymwt-control,
.css-t3ipsp-control:focus,
.css-t3ipsp-control:hover {
  border-radius: 0.5rem !important;
}

.css-t3ipsp-control:focus,
.css-t3ipsp-control:hover {
  box-shadow: 0 0 0 4px #d1e9ff, 0 1px 2px 0 rgb(16 24 40 / 5%) !important;
  border: 1px solid #84bff3 !important;
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 4px #d1e9ff, 0 1px 2px 0 rgb(16 24 40 / 5%) !important;
  border: 1px solid #84bff3 !important;
  border-radius: 0.5rem !important;
}

.css-qbdosj-Input input {
  box-shadow: none !important;
}

.css-13cymwt-control:hover {
  border-color: #d0d5dd !important;
}

.text-xs input {
  font-size: 12px;
}

.h-10 {
  height: 38px;
}

.gap-8 {
  gap: 30px;
}

.mt-8 {
  margin-top: 30px;
}

.badge {
  font-weight: 500;
}

.h-9 {
  height: 38px;
}

.input-suffix-start {
  left: 0.625rem;
}

.pagination-wrap .h-9 {
  height: 2.25rem;
}

.image_crop_style {
  border-radius: 50%;
}

/*
.input-shadow.h-10{height: 40px;}
*/

.select__control {
  border-radius: 8px !important;
}

.breadcrumbs-wrap li:last-child a span {
  color: #5786cc;
}

table th {
  white-space: nowrap;
}

.wrap-doc .upload-file {
  width: 100%;
}

.image_crop_style div:nth-child(2) > div > div > div {
  border-radius: 16px !important;
  background: #d2ebff !important;
}

.image_crop_style div:nth-child(2) > div > div > div span {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px;
  border: 1px solid #5786cc;
}

.image_crop_style div:nth-child(2) > div button {
  border-radius: 30px !important;
  width: 28px;
  height: 28px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.image_crop_style div:nth-child(2) > div button svg {
  width: 18px;
  height: 18px;
}

.image_crop_style div:nth-child(2) > div button:nth-child(2) {
  background: #5786cc !important;
  border-color: #5786cc !important;
}

.h-34 {
  height: 36px;
}

.upload-file-left a .upload-file-name {
  word-break: break-all;
}

.select-option-wrap .select__input {
  margin-left: 0 !important;
}

.select-option-wrap .select__placeholder {
  padding-left: 0;
}

.select-option-wrap .select__input-container {
  background-image: none !important;
}

.select-option-wrap {
  max-width: 200px;
  border: none !important;
}

.select-option-wrap .select__control.css-1ocetxi-control {
  border: none !important;
}

.select-option-wrap .select__control--is-focused {
  border: none !important;
}

.invoice-table td {
  border-right: 1px solid #e5e7eb;
}

.invoice-table tr td:last-child {
  border-right: none;
}

.total-invoice-wrap tr td {
  padding-left: 0;
  padding-right: 0;
}

.total-invoice-wrap tr:first-child td {
  border-bottom: 1px solid #fff !important;
}

.top-10px {
  top: 10px;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PScwIDAgMTYgMTYnIGZpbGw9JyM1Nzg2Y2MnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTEyLjIwNyA0Ljc5M2ExIDEgMCAwMTAgMS40MTRsLTUgNWExIDEgMCAwMS0xLjQxNCAwbC0yLTJhMSAxIDAgMDExLjQxNC0xLjQxNEw2LjUgOS4wODZsNC4yOTMtNC4yOTNhMSAxIDAgMDExLjQxNCAweicvPjwvc3ZnPg==") !important;
}

.upload-file-left {
  width: calc(100% - 30px);
}

.hide-down-arrow .select__indicators {
  display: none !important;
}

label {
  font-size: 12px;
  font-weight: 500;
}

.css-1p3m7a8-multiValue {
  background-color: #eff8ff !important;
  border-radius: 10px !important;
}

.ql-snow .ql-tooltip input[type="text"] {
  border: 1px solid #e5e7eb !important;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
  height: 34px !important;
  border-radius: 8px;
}

.ql-snow .ql-tooltip input[type="text"]:focus {
  box-shadow: 0 0 0 4px #d1e9ff, 0 1px 2px 0 rgb(16 24 40 / 5%) !important;
  border: 1px solid #84bff3 !important;
}

.ql-snow .ql-tooltip {
  border: 1px solid #e5e7eb !important;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
  border-radius: 12px;
  left: 15px !important;
}

.h-full-wrap .select__control {
  height: 100%;
}

.ql-toolbar.ql-snow {
  padding: 8px 15px !important;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-header:hover svg path {
  stroke: #5786cc !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon {
  display: none;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZXZyb24tZG93biI+CjxwYXRoIGlkPSJJY29uIiBkPSJNNS42MDAxIDcuOTgxNDVMMTAuNjAwMSAxMi45ODE0TDE1LjYwMDEgNy45ODE0NSIgc3Ryb2tlPSIjNjY3MDg1IiBzdHJva2Utd2lkdGg9IjEuNjY2NjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: right 8px bottom 0;
}

.relative.z-10 {
  display: block;
  width: 100%;
}

.relative.z-10 > * {
  z-index: -1;
  position: relative;
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0IiBjeT0iNCIgcj0iNCIgZmlsbD0iIzU3ODZDQyIvPgo8L3N2Zz4K") !important;
  background-size: 8px 8px;
}

.gray-svg svg {
  stroke: #98a2b3;
}

.input-suffix-start.right-set {
  right: 0.625rem;
  left: auto;
}

/*.table-responsive{
  overflow: hidden;
  border-radius: 8px;
}
*/

.email-subject-wrap {
  white-space: nowrap;
  max-width: 230px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.avatar-img,
.avtar_img_round img {
  object-fit: contain;
}

.min-w-72 {
  min-width: 288px;
}

.date-picker-wrap .picker {
  margin-left: -160px;
}

/* Pagination Css Code */
.choose-show-fields .select__control.css-1ocetxi-control {
  background: #f2f4f7;
  border-radius: 8px;
  border: none;
}

.choose-show-fields .select__placeholder,
.choose-show-fields .select__single-value {
  padding-left: 0;
}

.choose-show-fields .select__input-container {
  background: none;
}

.choose-show-fields .select__input {
  margin-left: 0 !important;
}

.wrap-dashboard-table table tr.bg-gray-50 {
  border-top: 1px solid #eaecf0;
  border-bottom: none;
}

.wrap-dashboard-table table.table-default tr:nth-child(2) {
  border-top: none;
}

.avatar-string {
  display: flex;
  background-color: #5786cc;
  justify-content: center;
  width: 40px;
  color: rgb(255 255 255 /1);
  font-weight: 600;
  border-radius: 9999px;
}

button {
  cursor: pointer;
}

.custom-select {
  position: relative;
  display: inline-flex;
  width: 100%;
}

.custom-select input {
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  height: 36px;
  padding: 8px 14px 8px 14px;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
}

.custom-select button {
  margin-left: -30px;
}

.custom-select ul {
  position: absolute;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
  border: 1px solid #eaecf0;
  background: #fff;
  border-radius: 8px;
  left: 0;
  right: 0;
  padding: 10px 4px;
  top: 38px;
}

.custom-select ul li {
  padding: 7px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.custom-select ul li:hover {
  background-color: #d2ebff;
}

.custom-select ul li[aria-selected="true"] {
  background-color: #d2ebff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xNy4xNjYgNSA4IDE0LjE2NyAzLjgzMyAxMCIgc3Ryb2tlPSIjNUFCOEVEIiBzdHJva2Utd2lkdGg9IjEuNjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  background-repeat: no-repeat;
  background-position: center right 10px;
}

.custom-select input:focus,
.custom-select:focus input {
  box-shadow: 0 0 0 4px #d1e9ff, 0 1px 2px 0 rgb(16 24 40 / 5%) !important;
  border: 1px solid #84bff3 !important;
}

:focus {
  outline-color: #84bff3;
}

.dialog-content {
  transform: initial !important;
}

@media only screen and (max-width: 1500px) {
  .induction_iframe {
    width: 800px;
    height: 500px;
  }

  .video-container {
    width: initial;
    height: initial;
  }
}

@media only screen and (max-width: 1440px) {
  .induction_iframe {
    width: 700px;
    height: 450px;
  }

  .video-container {
    width: initial;
    height: initial;
  }
}

@media only screen and (max-width: 1200px) {
  .induction_iframe {
    width: 650px;
    height: 350px;
  }

  .video-container {
    width: initial;
    height: initial;
  }
}

.add_file_btn {
  width: 108px;
}

.add_custom_field {
  gap: 8px;
  cursor: pointer;
}

/* Apply spin animation to spinner */
.spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Email log Advance Search */
.filter-block .dropdown .dropdown-toggle {
  padding: 0;
}

.filter-block .dropdown-menu {
  right: 0;
  width: 500px;
  margin-top: 10px;
}

.filter-block .block button.text-blue-gray-900 {
  background: #f5faff;
}

.filter-block .block button.justify-between {
  font-size: 14px;
  padding: 12px;
  border: none;
  border-radius: 12px;
}

.filter-block .block button.justify-between svg.active path:first-child {
  opacity: 0;
}

.filter-block .block button.justify-between span.w-5.h-5 {
  align-items: center;
  display: flex;
}
.filter-block.active .dropdown-toggle button {
  background-color: #e5f3fe;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

/* React Date Range Calender Css */
.hide-input-range .rdrInputRanges {
  display: none;
}

.rdrStaticRanges .rdrStaticRange:hover {
  border: none !important;
}

/* Apply border only when static range is clicked */
.rdrStaticRanges .rdrStaticRange.isActiveDate {
  border: 2px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
}
.isActiveDate {
  border: none;
  /* Border style for the active date range */
  border-radius: 5px;
  /* Optional: Rounded border edges */
}

/* Define CSS for the inactive date ranges */
.DateRangePicker
  .DateRangePickerInput__calendar--horizontal
  .DateRangePickerInput__calendar__month {
  display: none;
  /* Hide month calendar initially */
}

/* Show month calendar on hover */
.DateRangePicker
  .DateRangePickerInput__calendar--horizontal:hover
  .DateRangePickerInput__calendar__month {
  display: block;
}

.rdrDefinedRangesWrapper {
  width: 117px !important;
  border-right: none !important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: transparent !important;
}

.rdrStaticRange {
  border-bottom: none !important;
  border-radius: 6px;
}

.rdrStaticRange.rdrStaticRangeSelected {
  color: #344054 !important;
  background: #e5f3fe;
  font-weight: 500 !important;
}

.rdrWeekDay {
  font-weight: 500 !important;
  color: #344054 !important;
}

.rdrStaticRangeLabel {
  padding: 10px 10px !important;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.rdrDay {
  line-height: 40px !important;
  height: 40px !important;
  border-bottom: 1px solid #fff !important;
  border-top: 1px solid #fff !important;
}

.rdrEndEdge {
  right: 0 !important;
  height: auto !important;
}

.rdrDateDisplayWrapper {
  background-color: transparent !important;
}

.rdrDayToday .rdrDayNumber span:after {
  width: 5px !important;
  height: 5px !important;
}

.rdrDayNumber {
  top: 0 !important;
  bottom: 0 !important;
}

.rdrStartEdge,
.rdrEndEdge {
  background: #84bff3 !important;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: #84bff3 !important;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  background: transparent !important;
  top: 0 !important;
  left: 0px;
  right: 0px;
  bottom: 0 !important;
  pointer-events: none;
  border: 1px solid #84bff3 !important;
  z-index: 1;
}

.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
  filter: none !important;
}

.calander_wrapper .rdrCalendarWrapper.rdrDateRangeWrapper div:nth-child(1) {
  order: 1;
}

.calander_wrapper .rdrCalendarWrapper.rdrDateRangeWrapper div:nth-child(2) {
  order: 0;
}

.calander_wrapper .rdrCalendarWrapper.rdrDateRangeWrapper div:nth-child(3) {
  order: 2;
}

.calander_wrapper .rdrCalendarWrapper.rdrDateRangeWrapper .rdrDayDisabled {
  background-color: transparent !important;
}

.calander_wrapper
  .rdrCalendarWrapper.rdrDateRangeWrapper
  .rdrDayPassive
  .rdrDayNumber
  span {
  color: #d5dce0 !important;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  border: 1px solid transparent !important;
}

.rdrDateDisplayItem input {
  height: 40px !important;
}

.rdrDateDisplayItem {
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
}

.rdrMonthAndYearPickers {
  font-weight: 600;
  color: #344054 !important;
  font-size: 14px !important;
}

.rdrPprevButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjUgMTFMMS41IDZMNi41IDEiIHN0cm9rZT0iIzY2NzA4NSIgc3Ryb2tlLXdpZHRoPSIxLjY2NjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") !important;
  background-position: left center !important;
}

.rdrNextButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjUgMTFMNi41IDZMMS41IDEiIHN0cm9rZT0iIzY2NzA4NSIgc3Ryb2tlLXdpZHRoPSIxLjY2NjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") !important;
  background-position: right center !important;
}

.rdrNextPrevButton i {
  display: none !important;
}

.rdrNextButton,
.rdrPprevButton {
  background-repeat: no-repeat !important;

  padding: 0 !important;
}

.rdrMonthAndYearWrapper {
  align-items: start !important;
  padding-top: 0 !important;
  height: auto !important;
  margin-bottom: 25px;
}

.rdrCalendarWrapper {
  padding-left: 10px !important;
}

.rdrDateDisplay {
  margin-top: 0 !important;
}

.rdrDateDisplayItem {
  box-shadow: none !important;
}

.rdrDateDisplayWrapper .rdrDateDisplay:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 5px;
  background-color: #667085;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 20px;
}

.rdrDateDisplayItem {
  flex: none !important;
  width: 45% !important;
  margin: 0 !important;
}

.rdrDateDisplay {
  position: relative;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #344054 !important ;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #fff !important;
}

.custom-scrollbar .table-responsive {
  overflow-y: auto;
  max-height: 270px;
}

.custom-scrollbar .table-responsive table thead {
  position: sticky;
  top: 0;
}

.custom-scrollbar .table-responsive::-webkit-scrollbar {
  width: 8px;
  border-radius: 12px;
}

.custom-scrollbar .table-responsive::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.custom-scrollbar .table-responsive::-webkit-scrollbar-thumb {
  background: #d0d5dd;
  border-radius: 10px;
}

.rdrDateDisplayItemActive {
  border-color: #84bff3 !important;
}

.min-h-10,
.min-h-10 .css-1ocetxi-control {
  min-height: 38px;
}

.header_lougout {
  width: 100%;
  justify-content: start !important;
}

.remove-border tr td,
.provider-info-table tr td {
  padding-left: 0;
}

.remove-border .divide-y.divide-gray-200 tr {
  border-top: none !important;
  border-bottom: none !important;
}

.provider-info-table .divide-y tr {
  border-top: none !important;
  border-bottom: none !important;
}

.provider-info-table .divide-y tr:nth-of-type(3n + 1) {
  border-top: 1px solid #e5e7eb !important;
}

.provider-info-table .divide-y tr:first-child {
  border-top: none !important;
}

.dropdown-content {
  position: absolute;
  z-index: 9;
  background: #fff;
  width: 500px;
  left: 0;
  margin-left: -50px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
  padding: 8px;
  border-radius: 8px;
}

.dropdown-content .reset-accordian-btn {
  margin-top: 0;
  border: none;
  background: #f5faff;
  padding: 12px;
  border-radius: 12px !important;
  margin-bottom: 5px;
}

.dropdown-content .accordion-body {
  border: none !important;
}

.checkbox-group .checkbox-label {
  font-size: 14px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 10px;
}

.checkbox-group .checkbox {
  margin-right: 0;
}

.custom_dropdown {
  width: 100% !important;
}

.image_viewer {
  padding: 16px 45px !important;
}

/* Overlay to cover the entire screen */
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it is on top of all other elements */
}

/* Spinner and text container */
.spinner-container {
  text-align: center;
  color: white;
}

/* Spinner loader */
.three-dot-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background: white; /* Change to white for better visibility */
  border-radius: 50%;
  animation: dot-blink 1.4s infinite both;
}

@keyframes dot-blink {
  0%,
  20%,
  50%,
  80%,
  100% {
    opacity: 1;
  }
  40% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.5;
  }
}
.auto-height {
  height: auto;
}

input[disabled] {
  pointer-events: none;
  opacity: 0.8; /* Optional: Adjust the opacity for visual effect */
  background: #f2f4f7;
}

.twoFaStep {
  background-color: #def0ff;
}

.v-align-top tr td {
  vertical-align: top;
}

/* Image Uploder  */

.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.image-input {
  display: none;
}

.uploaded-image {
  height: 300px;
  width: 225px;
  border-radius: 12px;
  object-fit: cover;
  cursor: pointer;
}

/* doc view only */
.doc-width-50 {
  width: 70%;
}

.pdf-shadow {
  box-shadow: 0px 0px 16.26px 0px #1018282e;
}

.pdf-btn-block {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.pdf-btn-block button {
  background-color: #fff;
}

.pdf-btn-block button:hover,
.pdf-btn-block button:focus {
  background-color: #f5faff;
}

.pdf-btn-block button:first-child {
  border-radius: 0 0 0 12px;
}

.pdf-btn-block button:nth-child(2) {
  border-radius: 0 0 12px 0;
}

.pdf_btn_center {
  padding: 0 60px !important;
}

#divToPrint {
  margin: 0 auto;
}
.view_pdf {
  width: 100%;
  margin: 0 auto;
}

@media print {
  img {
    max-width: 100%;
    height: auto;
  }
}

/* body {
  background-image: url('https://via.placeholder.com/800x600');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
} */

.content {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Adding a semi-transparent background to content for better readability */
  padding: 20px 20px 30px 20px;
}

/* Ensure background images are not excluded from printing */

@media print {
  body {
    -webkit-print-color-adjust: exact; /* Chrome, Safari */
    print-color-adjust: exact; /* Firefox */
  }
}

@media only screen and (max-width: 767px) {
  .table-responsive {
    overflow-x: auto;
  }
}

table .reset-dropdown .dropdown .dropdown-toggle button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.threeDotLine span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
}
.FabButtonFramecss__FabButtonFrameUI-sc-y1b4md-0 {
  display: none !important;
}

.BeaconContainer {
  position: fixed !important;
  left: 50% !important;
  top: calc(50% + 45px) !important;
  transform: translate(-50%, -50%) !important;
  width: 850px !important;
  height: 350px !important;
}

.noti-newwrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-notify-w {
  width: 47%;
}
.personal-notify {
  background-color: #b5c7d4;
}
.personal-noti-on {
  background-color: #d9ebf6;
}

.rich-text-area-wrap .rich-text-editor {
  height: 133px;
}
.rich-text-area-wrap .rich-text-editor .quill,
.rich-text-area-wrap .rich-text-editor .ql-container {
  min-height: auto;
  height: 80px;
}

.provider_setting .dailog-footer {
  padding: 0 !important;
  margin: 0 !important;
}

.provider_setting .provider_user {
  padding: 12px !important;
  margin: 12px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* For Firefox */
}
